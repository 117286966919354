<template>
  <v-container class="pa-6" fluid>
    <h2 class="sidebar-title mt-2 mb-6">{{ this.pageName }}</h2>
    <v-row>
      <v-col cols="3">
        <h2 class="sidebar-title sidebar-title-small mb-6">Restaurants</h2>
        <v-text-field @input="handleVenuesSearch" solo label="Find restaurants" class="standart-input mt-3" flat
          prepend-inner-icon="mdi-magnify" type="text"></v-text-field>
        <GroupTab @folderClick="folderClick" @tabClick="tabClick" :isArea="isArea" :list="venues"
          :activeFolderId="activeVenueGroupId" :activeTabId="activeVenueId" />
      </v-col>
      <v-col>
        <div class="standart-card">
          <v-row>
            <v-col class="col-lg-10 col-9">
              <v-text-field @input="handleSearch" solo :label="`Find ${this.pageNameSingular}`" class="standart-input"
                flat prepend-inner-icon="mdi-magnify" type="text"></v-text-field>
            </v-col>
            <v-col>
              <router-link :to="isLoading ? '#' : `/${this.pageNameSingular}/new`"
                class="standart-button standart-button-secondary">New {{ this.pageNameSingular }}</router-link>
            </v-col>
          </v-row>
          <EntityList :isLoading="isEntitiesLoading" :list="entityList" :isSearched="isSearched" :isShowEmail="true"
            :detailUrl="this.pageNameSingular" :groupId="this.getActiveVenueGroupId" @handleDelete="deleteEntityItem" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { debounce } from 'vue-debounce';
import GroupTab from '../components/GroupTab.vue';
import EntityList from '../components/EntityList.vue';
import { TOAST_TYPES } from '@/data';

export default {
  name: 'Managers',
  components: {
    GroupTab,
    EntityList,
  },
  data() {
    return {
      isArea: false,
      venues: [],
      searchValue: '',
      pageName: 'managers',
      pageNameSingular: 'manager',
      venuesSearchValue: '',
    };
  },
  computed: {
    ...mapGetters({
      entityList: 'managers/getList',
      isEntitiesLoading: 'managers/isLoading',
      getActiveVenueGroupId: 'managers/getActiveVenueGroupId',
      getActiveVenueId: 'managers/getActiveVenueId',
      getSearch: 'managers/getSearch',
      isLoading: 'managers/isLoading',
      getUserData: 'app/getUserData',
      getVenues: 'venues/getData',
    }),
    isSearched() {
      return Boolean(this.searchValue.length);
    },
    activeVenueGroupId() {
      return this.getActiveVenueGroupId;
    },
    activeVenueId() {
      return this.getActiveVenueId;
    }
  },
  created() {
    if (this.getUserData.roleNames[0].toLowerCase() === 'dealer') {
      this.getVenueGroups(this.getUserData.areaId)
    } else {
      this.getVenueGroups(null)
    }
  },
  async mounted() {
    if (this.getUserData.roleNames[0].toLowerCase() === 'dealer') {
      await this.getVenueGroups(this.getUserData.areaId)
    } else {
      this.setManagersList([]);
      await this.getVenueGroups(null)
      // this.fetchVenues().then(() => {
      //   if (this.getActiveVenueGroupId !== -1) {
      //     const firstGroupId = -1;
      //     this.setActiveVenueGroupId(firstGroupId);
      //   }
      // });
      if (this.getSearch) {
        this.fetchEntitiesById(this.activeVenueGroupId);
        this.clearSearch();
      }
    }
  },
  methods: {
    ...mapActions({
      fetchVenueGroups: 'venueGroups/fetchData',
      fetchVenues: 'venues/fetchData',
      fetchEntities: 'managers/fetchList',
      searchEntities: 'managers/search',
      deleteEntity: 'managers/deleteItem',
      searchVenues: 'venues/searchVenues',
    }),
    ...mapMutations({
      setSearch: 'managers/setSearch',
      clearSearch: 'managers/clearSearch',
      setActiveVenueGroupId: 'managers/setActiveVenueGroupId',
      setActiveVenueId: 'managers/setActiveVenueId',
      setManagersList: 'managers/setList',
    }),
    folderClick(id) {
      this.setManagersList([]);
      this.setActiveVenueGroupId(id)
    },
    tabClick(id) {
      this.setActiveVenueId(id)
      this.fetchEntitiesById(id);
    },
    fetchEntitiesById(id) {
      this.setActiveVenueGroupId(id);
      this.fetchEntities(`/${this.pageName}?venueId=${id}`);
    },
    deleteEntityItem(id) {
      this.deleteEntity(id).then(data => {
        if (data) {
          this.$toast.open({ message: 'Manager has been successfully deleted!', type: TOAST_TYPES.SUCCESS });
        }
      });
    },
    async getVenueGroups(id) {
      this.isArea = true
      id
        ? this.fetchVenueGroups(`/VenueGroups?areaId=${id}`).then(data => {
          this.venues = data.map(venueGroup => {
            const fetchedVenue = []
            this.fetchVenues(`/venues?areaId=${venueGroup.areaId}&groupId=${venueGroup.id}`).then((data) => {
              fetchedVenue.push(...data)
            })
            return {
              ...venueGroup,
              child: fetchedVenue
            }
          })
        })
        : this.fetchVenueGroups().then(data => {
          this.venues = data.map(venueGroup => {
            const fetchedVenue = []
            this.fetchVenues(`/venues?groupId=${venueGroup.id}`).then((data) => {
              fetchedVenue.push(...data)
            })
            return {
              ...venueGroup,
              child: fetchedVenue
            }
          })
        })
    },
    handleSearch: debounce(function (searchQuery) {
      this.searchValue = searchQuery;
      this.setSearch(searchQuery);
      const url = `/${this.pageName}?venueGroupId=${this.activeVenueGroupId}&partOfUserName=${searchQuery}`;
      this.searchEntities(url);
    }, '1000ms'),
    handleVenuesSearch: debounce(function (searchQuery) {
      if (searchQuery) {
        this.isArea = false
        this.venuesSearchValue = searchQuery;
        this.setSearch(searchQuery);
        const url = `/venues?areaId=${this.getUserData.areaId}&partOfName=${searchQuery}`;
        this.searchVenues(url).then(() => {
          this.venues = this.getVenues
        })
      } else {
        this.getVenueGroups(this.getUserData.areaId)
      }
    }, '1000ms'),
  },
};
</script>

<style lang="scss"></style>
